export default {
    'case': [
        {
            id: 1,
            banner: require('@/assets/case/db.jpg'),
            title: '鹃城豆瓣',
            stitle: '案例>鹃城豆瓣',
            time: '2021-2-26 09:20:56',
            contentList: [
                {
                    title: '企业介绍',
                    txt: `四川省郫县豆瓣股份有限公司位于成都市郫县郫筒镇南大街180号，占地近10万平方米，总资产9000多万，年销售额过亿。拥有员工400余人，各类工程技术人员，大中专毕业生200余人。年产“鹃城”牌郫县豆瓣及系列产品二万余顿，是西南地区历史最悠久的调味品生产企业。`
                },
                {title: '企业痛点', txt: `经销商窜货，订单生产进度数据不及时，订单生产进度数据不及时，终端促销无法灵活调整，促销被截流，逆向追溯无法实现。`},
                {
                    title: '实现功能',
                    txt: `<div>一物一码管理</div> <div>多级关联装箱</div><div>生产报工</div><div>扫码出入库</div><div>逆向追溯查询</div>`
                },
                {
                    title: '实现价值',
                    txt: `通过这套系统，实现了全流程的数据追溯。能实时掌握产线生产进度及数量，在车间实现报工。通过PDA扫码出入库，自动统计重量，提高出库人效率。实现箱到跺的关联，扫码出库与运输单绑定，实现防窜货。唯一码，扫码次数预警，实现防伪功能。`
                },
            ],
            list: [
                {src: require('@/assets/case/jclogo.png'), title: '娟城豆瓣', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xlwlogo.png'), title: '肖老五', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xxwlogo.png'), title: '新希望V美', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/abalogo.png'), title: '阿坝铝厂', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/bzllogo.png'), title: '宾之郎', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/hdllogo.png'), title: '红灯笼', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/chlogo.png'), title: '长虹新能源', tiem: '2020-03-06  10:52:11'},
            ],
        },


        {
            id: 2,
            banner: require('@/assets/case/xlw.jpg'),
            title: '肖佬五',
            stitle: '案例>肖佬五',
            time: '2021-2-26 09:20:56',
            contentList: [
                {
                    title: '企业介绍',
                    txt: `“肖佬五”始创于1989年，是纯正火锅牛油缔造者和重庆麻辣火锅灵魂之所在，是中国著名品牌和中国火锅底料十大品牌之一。“肖佬五”成就了无数的火锅店和渠道商，对于他们来说，“肖佬五”是回忆，是情结，更是一起创业和成长的兄弟。`
                },
                {title: '企业痛点', txt: `经销商窜货，订单生产进度数据不及时，订单生产进度数据不及时，终端促销无法灵活调整，促销被截流，逆向追溯无法实现。`},
                {
                    title: '实现功能',
                    txt: `
                        <div>终端门店促销管理</div> 
                        <div>防窜货监管</div>
                        <div>多级经销促销</div>
                        <div>商城管理</div>
                        <div>数据分析管理</div>
                        <div>成品物流管理</div>`
                },
                {
                    title: '实现价值',
                    txt: `通过这套系统，实现了全流程的数据追溯。能实时掌握产线生产进度及数量，在车间实现报工。通过PDA扫码出入库，自动统计重量，提高出库人效率。实现箱到跺的关联，扫码出库与运输单绑定，实现防窜货。唯一码，扫码次数预警，实现防伪功能。`
                },
            ],
            list: [
                {src: require('@/assets/case/jclogo.png'), title: '娟城豆瓣', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xlwlogo.png'), title: '肖佬五', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xxwlogo.png'), title: '新希望V美', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/abalogo.png'), title: '阿坝铝厂', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/bzllogo.png'), title: '宾之郎', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/hdllogo.png'), title: '红灯笼', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/chlogo.png'), title: '长虹新能源', tiem: '2020-03-06  10:52:11'},
            ],
        },

        {
            id: 3,
            banner: require('@/assets/case/xxw.jpg'),
            title: '新希望V美',
            stitle: '案例>新希望V美',
            time: '2021-2-26 09:20:56',
            contentList: [
                {
                    title: '企业介绍',
                    txt: `四川新希望营养有限公司（简称C美公司）成立于2011年，是新希望乳业股份有限公司旗下专注于饮料业务的子公司。公司业务遍布全国20个省份；具备自主研发能力和优秀的博士研发团队，并与世界Top500原料供应商持续合作。`
                },
                {title: '企业痛点', txt: `调拨奖励无法落地，经销商之间恶意窜货，无法对滞销区域单独实施促销，终端门店无法享受促销政策。  `},
                {
                    title: '实现功能',
                    txt: `
                        <div>终端门店促销管理</div> 
                        <div>防窜货监管</div>
                        <div>成品物流管理</div>
                        <div>多级经销促销管理</div>
                        <div>数据分析管理</div>
                        <div>库房发货管理 </div>`
                },
                {
                    title: '实现价值',
                    txt: `通过红包奖励，单品销售月增加11.58%的销量，复购率增加8.13%。基于LBS位置服务，有效遏制区域之间窜货。企业可以单独针对产品滞销区域，单独实施促销政策，稳量增销。通过红包促销，采集终端市场信息，满足新品调研报告采集数据的及时性和准确性。
`
                },
            ],
            list: [
                {src: require('@/assets/case/jclogo.png'), title: '娟城豆瓣', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xlwlogo.png'), title: '肖佬五', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xxwlogo.png'), title: '新希望V美', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/abalogo.png'), title: '阿坝铝厂', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/bzllogo.png'), title: '宾之郎', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/hdllogo.png'), title: '红灯笼', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/chlogo.png'), title: '长虹新能源', tiem: '2020-03-06  10:52:11'},
            ],
        },
        {
            id: 4,
            banner: require('@/assets/case/xxw.jpg'),
            title: '阿坝铝厂',
            stitle: '案例>阿坝铝厂',
            time: '2021-2-26 09:20:56',
            contentList: [
                {
                    title: '企业介绍',
                    txt: `四川新希望营养有限公司（简称C美公司）成立于2011年，是新希望乳业股份有限公司旗下专注于饮料业务的子公司。公司业务遍布全国20个省份；具备自主研发能力和优秀的博士研发团队，并与世界Top500原料供应商持续合作。`
                },
                {title: '企业痛点', txt: `调拨奖励无法落地，经销商之间恶意窜货，无法对滞销区域单独实施促销，终端门店无法享受促销政策。  `},
                {
                    title: '实现功能',
                    txt: `
                        <div>终端门店促销管理</div> 
                        <div>防窜货监管</div>
                        <div>成品物流管理</div>
                        <div>多级经销促销管理</div>
                        <div>数据分析管理</div>
                        <div>库房发货管理 </div>`
                },
                {
                    title: '实现价值',
                    txt: `通过红包奖励，单品销售月增加11.58%的销量，复购率增加8.13%。基于LBS位置服务，有效遏制区域之间窜货。企业可以单独针对产品滞销区域，单独实施促销政策，稳量增销。通过红包促销，采集终端市场信息，满足新品调研报告采集数据的及时性和准确性。
`
                },
            ],
            list: [
                {src: require('@/assets/case/jclogo.png'), title: '娟城豆瓣', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xlwlogo.png'), title: '肖佬五', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/xxwlogo.png'), title: '新希望V美', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/abalogo.png'), title: '阿坝铝厂', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/bzllogo.png'), title: '宾之郎', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/hdllogo.png'), title: '红灯笼', tiem: '2020-03-06  10:52:11'},
                {src: require('@/assets/case/chlogo.png'), title: '长虹新能源', tiem: '2020-03-06  10:52:11'},
            ],
        },
    ]
}



