import { MessageBox } from 'element-ui';

import json from './json.js'

export function confirmation( target, name, descriptor ) {
    let oldValue = descriptor.value;
    descriptor.value = function ( ...args ) {
        MessageBox.confirm( '此操作将永久删除该文件, 是否继续?', '提示' )
            .then( oldValue.bind( this, ...args ) )
            .catch( () => {
            } );
    };

    return descriptor;
}


export function scrolTop( target, name, descriptor ) {
    let fn = descriptor.value;

    descriptor.value = function ( ...args ) {
        scrollTo( { top: 0 } );
        fn.call( this, ...args );
    };

    return descriptor;

}


export function pushRouter( target, name, descriptor ) {
    let fn = descriptor.value;
    descriptor.value = function ( ...args ) {
        const [ path ] = args;
        this.$router.push( { path } );
        fn.bind( this, ...args )
    };

    return descriptor;
}

export function Data( key ) {
    return function ( target, name, descriptor ) {
        let fn = descriptor.value;
        descriptor.value = function ( ...args ) {
            const [ name ] = args;

            const data = localStorage.getItem( 'data' );

            if ( data ) {
                this.d = !key ? JSON.parse( data ) : JSON.parse( data )[key];
                fn.call( this, ...args );
            }

        };
        return descriptor;
    }
}


// 产品调转
export function productJump() {
    return function ( target, name, descriptor ) {
        let fn = descriptor.value;
        descriptor.value = function ( ...args ) {
            const [ id ] = args;
            fn.call( this, ...args );
        };
        return descriptor;
    }
}
